import React, {useState, useEffect} from 'react'
import { Layout, List, Avatar } from 'antd'
import axios from 'axios'

export default function ContentBlock() { 
  const [ reqList, setReqList ] = useState([])
  useEffect(() => {
    async function fetchData() {
        axios.get(`https://smartclub.site/api/finances/all`).then((response) => {
            setReqList(response.data.result);
          });
       
    }
    fetchData ()
  },[])

  const Confirm = async(id_telegram, summ, withdrawalType) => {
    if (isNaN(summ)) {
      const normalizedInput = summ.replace(',', '.');
      // Преобразуем строку в число
      const number = parseFloat(normalizedInput);
  
      // Проверяем, является ли введенное значение числом
      if (isNaN(number)) {
        summ = 0
      } else {
        summ = number
      }
    }
   
    await axios.post('https://smartclub.site/api/finances/action', {
      id_telegram: id_telegram,
      summ: summ,
      action: 'confirm',
      withdrawalType: withdrawalType
    })
    .then(function (response) {
      if (response.data.stat) {
        setReqList(currentState => currentState.filter(item => item.id_telegram !== id_telegram || item.withdrawalType != withdrawalType));        
      }
    })
  }

  const Reject = async (id_telegram, summ, withdrawalType) => {
    await axios.post('https://smartclub.site/api/finances/action', {
      id_telegram: id_telegram,
      summ: summ,
      action: 'delete',
      withdrawalType: withdrawalType
    })
    .then(function (response) {
      if (response.data.stat) {
        setReqList(currentState => currentState.filter(item => item.id_telegram !== id_telegram || item.withdrawalType != withdrawalType));        
      }
    })
  }

  return (
    <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={reqList}
          renderItem={(item) => (
            <List.Item
              actions={[<a key="list-loadmore-edit" onClick={() => Confirm(item.id_telegram, item.summ, item.withdrawalType)}>Подтвердить</a>, <a key="list-loadmore-more" onClick={() => Reject(item.id_telegram, item.summ, item.withdrawalType)}>Отклонить</a>]}
            >
                <List.Item.Meta
                  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.index}`} />}
                  title={<a href={"/admin/user/" + item.id_telegram}>{item.username}</a>}
                  description={<><div><span>email:  </span>{item.email}</div><br/><div><span>withdrawalType:  </span>{item.withdrawalType}</div><br/><div><span>hash_login:  </span>{item.hash_login}</div><br/><div><span>Сума:  </span>{item.summ}</div></>}
                />
                {/* <div>content</div> */}
            </List.Item>
          )}
        />)
}