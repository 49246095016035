import { useState, useEffect } from "react"
import Slider from "../Slider/Slider"
import { Tabs, Switch, Select, Flex, Progress, ConfigProvider } from 'antd';
import language from '../../language/language.js'
import { useTelegram } from "../../hooks/useTelegram"


export default function Trade({ onTabClub, onTabTrade, userData }) {
    const { tg, user, telegram_id, first_name } = useTelegram()

    const targetDate = '2024-10-01'
    const lang = (userData.data.lang ? userData.data.lang : 'ru')
    const [daysRemaining, setDaysRemaining] = useState(0);

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        // Функция для вычисления оставшихся дней
        const calculateDaysRemaining = () => {
            const now = new Date(); // текущая дата
            const target = new Date(targetDate); // целевая дата

            // Вычисление разницы в миллисекундах между датами
            const difference = target - now;

            // Переводим миллисекунды в дни
            const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

            // Устанавливаем значение оставшихся дней
            setDaysRemaining(days);
        };

        // Запуск расчета
        calculateDaysRemaining();

        // Обновление каждый день
        const timerId = setInterval(calculateDaysRemaining, 1000 * 60 * 60 * 24);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(timerId);
    }, [targetDate]);
    // const items =
    // [
    //     {
    //         key: '1',
    //         label: 'Все',
    //         children: (userData.referralsTrade?.level1 ? userData.referralsTrade.level1.map(referral => ({ ...referral, label: '1' })).concat(userData.referralsTrade.level2.map(referral => ({ ...referral, label: '2' })), userData.referralsTrade.level3.map(referral => ({ ...referral, label: '3' })), userData.referralsTrade.level4.map(referral => ({ ...referral, label: '4' })), userData.referralsTrade.level5.map(referral => ({ ...referral, label: '5' }))) : []),
    //     },
    //     {
    //         key: '2',
    //         label: '1 ур',
    //         children: (userData.referralsTrade?.level1 ? userData.referralsTrade?.level1.map(referral => ({ ...referral, label: '1' })) : []),
    //     },
    //     {
    //         key: '3',
    //         label: '2 ур',
    //         children: (userData.referralsTrade?.level2 ? userData.referralsTrade?.level2.map(referral => ({ ...referral, label: '2' })) : []),
    //     },
    //     {
    //         key: '4',
    //         label: '3 ур',
    //         children: (userData.referralsTrade?.level3 ? userData.referralsTrade?.level3.map(referral => ({ ...referral, label: '3' })) : []),
    //     },
    //     {
    //         key: '5',
    //         label: '4 ур',
    //         children: (userData.referralsTrade?.level4 ? userData.referralsTrade?.level4.map(referral => ({ ...referral, label: '4' })) : []),
    //     },
    //     {
    //         key: '6',
    //         label: '5 ур',
    //         children: (userData.referralsTrade?.level5 ? userData.referralsTrade?.level5.map(referral => ({ ...referral, label: '5' })) : []),
    //     },
    // ]  

    const items =
        [
            {
                key: '1',
                label: language[lang].all,
                children: (userData.referrals?.level1 ? userData.referrals.level1.map(referral => ({ ...referral, label: '1' })).concat(userData.referrals.level2.map(referral => ({ ...referral, label: '2' })), userData.referrals.level3.map(referral => ({ ...referral, label: '3' }))) : []),
            },
            {
                key: '2',
                label: '1 ' + language[lang].lvl,
                children: (userData.referrals?.level1 ? userData.referrals?.level1.map(referral => ({ ...referral, label: '1' })) : []),
            },
            {
                key: '3',
                label: '2 ' + language[lang].lvl,
                children: (userData.referrals?.level2 ? userData.referrals?.level2.map(referral => ({ ...referral, label: '2' })) : []),
            },
            {
                key: '4',
                label: '3 ' + language[lang].lvl,
                children: (userData.referrals?.level3 ? userData.referrals?.level3.map(referral => ({ ...referral, label: '3' })) : []),
            },
            {
                key: '5',
                label: '4 ' + language[lang].lvl,
                children: (userData.referralsTrade?.level4 ? userData.referralsTrade?.level4.map(referral => ({ ...referral, label: '4' })) : []),
            },
            {
                key: '6',
                label: '5 ' + language[lang].lvl,
                children: (userData.referralsTrade?.level5 ? userData.referralsTrade?.level5.map(referral => ({ ...referral, label: '5' })) : []),
            },
            // {
            //     key: '5',
            //     label: '4 ур',
            //     children: (userData.referrals?.level4 ? userData.referrals?.level4.map(referral => ({ ...referral, label: '4' })) : []),
            // },
            // {
            //     key: '6',
            //     label: '5 ур',
            //     children: (userData.referrals?.level5 ? userData.referrals?.level5.map(referral => ({ ...referral, label: '5' })) : []),
            // },
        ]

    return (
        <div className="MailList">
            <div className="heared">
                {/* <div className='languageBlock'>
                    <Select
                        defaultValue="RU"
                        size="small"
                        style={{
                            width: 'auto',
                        }}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'RU',
                                label: 'RU',
                            },
                            {
                                value: 'EN',
                                label: 'EN',
                            }
                        ]}
                    />
                </div> */}
                <div className="header__logo">
                    <h5>{language[lang].trade_title}</h5>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.6813 9.56507L20.0413 3.98959C19.5243 2.89069 18.1493 2 16.9833 2H7.01717C5.85116 2 4.47615 2.89069 3.95914 3.98959L1.3191 9.56507C0.681098 10.9069 1.0331 12.8155 2.07811 13.8219L9.7012 21.0977C10.9662 22.3008 13.0232 22.3008 14.2882 21.0977L21.9113 13.8219C22.9673 12.8155 23.3193 10.8953 22.6813 9.56507ZM16.4003 8.81319H12.8252V14.8861C12.8252 15.3603 12.4512 15.7536 12.0002 15.7536C11.5492 15.7536 11.1752 15.3603 11.1752 14.8861V8.81319H7.60017C7.14917 8.81319 6.77517 8.4199 6.77517 7.94563C6.77517 7.47137 7.14917 7.07808 7.60017 7.07808H16.4003C16.8513 7.07808 17.2253 7.47137 17.2253 7.94563C17.2253 8.4199 16.8513 8.81319 16.4003 8.81319Z" fill="#50C787" />
                        </svg>
                        <span className="header__logo--price">{userData?.data?.trade_balance.toFixed(2)}</span>
                        <span>USDT</span>
                    </div>
                </div>
                <div className="header__btn">
                    <a href="/finances/trade">
                        <img src={language[lang].button} alt="" />
                    </a>
                </div>
            </div>
            <div className="rewardBlock">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>

                    {
                        telegram_id === 763269687 || telegram_id === 1007460041 ?
                            <>
                                <div className="header__logo--price">
                                    {/* {daysRemaining > 0 ? `${language[lang].reward_text} ${daysRemaining} ${language[lang].day}` : language[lang].reward_confirm} */}
                                    LVL 1
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ marginRight: 10 }}>
                                        0
                                    </div>
                                    <div>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    Progress: {
                                                        remainingColor: '#949494'
                                                    }
                                                },
                                            }}
                                        >
                                            <Progress
                                                percent={50}
                                                percentPosition={{
                                                    align: 'start',
                                                    type: 'inner',
                                                }}
                                                size={[200, 20]}
                                                format={(percent) => <div style={{ color: '#ffffff' }}>{percent * 1000}</div>}
                                                strokeColor="#E6F4FF"
                                                style={{ margin: 0 }}
                                            />
                                        </ConfigProvider>

                                    </div>


                                </div>
                            </> : <></>

                    }

                </div>
                <div>

                    <span className="header__logo--price">
                        {/* {daysRemaining > 0 ? `${language[lang].reward_text} ${daysRemaining} ${language[lang].day}` : language[lang].reward_confirm} */}
                        Coming soon
                    </span>
                </div>
                <br />
                <div>
                    <span>Lite Reward:  </span>
                    <span className="header__logo--price">{userData?.data?.reward.toFixed(2)}</span>
                </div>

                <br />
                <div>
                    <span>Pro Reward:  </span>
                    <span className="header__logo--price">{userData?.data?.reward_full.toFixed(2)}</span>
                </div>
            </div>
            <Slider userData={userData} />
            {/* <div className="navigat">
                <div onClick={onTabClub}>
                    <span>Клубные вознаграждения</span>
                </div>
                <div className="activ" onClick={onTabTrade}>
                <span>Торговые вознаграждения</span>
                </div>
            </div> */}
            <div className="referal__list">
                <div className="referal__list--body">
                    <Tabs
                        className="referal__list--body--ul"
                        defaultActiveKey="1"
                        onChange={onChange}
                        indicator={{
                            size: 0,
                        }}
                        items={items.map((item) => ({
                            label: item.label,
                            key: item.key,
                            children: item.children.length > 0 ? item.children.map((child) => (
                                <div className="referal__list--body--li" key={child.id}>
                                    <div className="referal__list--body-li-left">
                                        <h5>{child.first_name}</h5>
                                        <a href={`https://t.me/${child?.username}`}><span>@{child?.username}</span></a>
                                        <h5 style={{
                                            color: child.trade_options ? 'green' : 'red'
                                        }}>{child.trade_options ? 'API: Active' : 'API: not Active'}</h5>
                                    </div>
                                    <div className="referal__list--body-li-right perenos">
                                        <h5>
                                            <svg width="11.000000" height="11.000000" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <defs />
                                                <path id="Vector" d="M-0.5 5C-0.5 1.96 1.96 -0.5 5 -0.5C8.03 -0.5 10.5 1.96 10.5 5C10.5 6.55 9.85 7.96 8.8 8.96C7.81 9.91 6.47 10.5 5 10.5C3.52 10.5 2.18 9.91 1.19 8.96C0.14 7.96 -0.5 6.55 -0.5 5ZM8.11 8.56C7.89 7.9 7.26 7.43 6.53 7.43L3.46 7.43C2.73 7.43 2.1 7.9 1.88 8.56C2.71 9.29 3.8 9.73 5 9.73C6.19 9.73 7.28 9.29 8.11 8.56ZM5 1.03C3.65 1.03 2.56 2.12 2.56 3.46C2.56 4.8 3.65 5.89 5 5.89C6.34 5.89 7.43 4.8 7.43 3.46C7.43 2.12 6.34 1.03 5 1.03Z" fill="#50C787" fill-opacity="1.000000" fill-rule="evenodd" />
                                            </svg>
                                            {child.label}
                                        </h5>
                                        <span>{child.email}</span>
                                    </div>
                                </div>
                            )) : (
                                <div className="referal__list--body--empty-block">
                                    {language[lang].ref_false}
                                </div>
                            )
                        }))}
                    />
                </div>
            </div>

        </div>
    )
}