import { useEffect, useState } from 'react';
import './App.css';
import Finances from './components/Finances/Finances';
import { Routes, Route } from 'react-router-dom';
import Main from './components/Main/Main';
import Register from './components/Register/Register';
import { useTelegram } from "./hooks/useTelegram"
import Admin from './components/Admin/Admin';
import AdminUser from './components/Admin/AdminUser';
import AdminList from './components/Admin/AdminList';
import TradeAdminList from './components/Admin/TradeAdminList';

import DiscordList from './components/Admin/DiscordList';

import FinancesAdminList from './components/Admin/FinancesAdminList';
import HistoryAdminList from './components/Admin/HistoryAdminList';
import Instructions from './components/Instructions/Instructions';
import TradeRegister from './components/Trade/TradeRegister';
import Presentation from './components/Instructions/Presentation';
import InfoPage from './components/InfoPage/InfoPage';
import FormInfoPage from './components/InfoPage/FormInfoPage';
import language from './language/language.js'



function App() {
  const {userData,tg, telegram_id} = useTelegram()
  const lang = (userData?.data?.lang ? userData.data.lang:'ru' )

  useEffect(() => {
    tg.ready()
  },[])

  const onClose = () => {
    tg.close()
  }

  return (
    <div>
    <div className="App">
      
      {/* <Header/> */}
      <Routes>
        <Route index element={<Main userData={userData}/>}/>
        <Route path={'/finances/trade'} element={<Finances wtype='trade' userData={userData} telegram_id={telegram_id}/>} />
        <Route path={'/finances/club'} element={<Finances wtype='club' userData={userData}/>} />
        <Route path={'/instructions'} element={<Instructions/>} userData={userData}/>
        <Route path={'/presentation'} element={<Presentation/>} userData={userData}/>

        <Route path={'/referals'} element={<InfoPage img={language[lang].referal_img} link={'https://t.me/+DQS1J9SyGtQyNDc6'} userData={userData}/>} />
        <Route path={'/investors'} element={<InfoPage img={language[lang].investor_img} link={'/investors/form'} userData={userData}/>}/>
        <Route path={'/investors/form'} element={<FormInfoPage userData={userData}/>} />


        
        <Route path={'/add/api'} element={<TradeRegister type='add' userData={userData}/>} />
        <Route path={'/form'} element={<Register/>} />
        <Route path={'/form/:recommended'} element={<Register/>} />

        <Route path={'/admin'} element={<Admin><AdminList></AdminList></Admin>} />
        <Route path={'/admin/user/:id_telegram'} element={<Admin><AdminUser>User</AdminUser></Admin>} />
        <Route path={'/admin/request/'} element={<Admin><TradeAdminList></TradeAdminList></Admin>} />
        <Route path={'/admin/discord/'} element={<Admin><DiscordList></DiscordList></Admin>} />
        <Route path={'/admin/finances/'} element={<Admin><FinancesAdminList></FinancesAdminList></Admin>} />
        <Route path={'/admin/history/'} element={<Admin><HistoryAdminList></HistoryAdminList></Admin>} />

      </Routes>
    </div>
    </div>
  );
}

export default App;
